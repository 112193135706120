import { render, staticRenderFns } from "./UnrecommendableReasonPage.vue?vue&type=template&id=9cf92aae&scoped=true"
import script from "./UnrecommendableReasonPage.vue?vue&type=script&lang=js"
export * from "./UnrecommendableReasonPage.vue?vue&type=script&lang=js"
import style0 from "./UnrecommendableReasonPage.vue?vue&type=style&index=0&id=9cf92aae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cf92aae",
  null
  
)

export default component.exports